import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../../app/store';
import { Recipe } from '../../types/Recipe';
import { sample } from "lodash";

const recipesUrl = "./recipes-dinner.json";

export interface RecipeState {
  recipes: Recipe[];
  selectedRecipe: Recipe | null;
  isLoaded: boolean;
}

const initialState: RecipeState = {
  recipes: [],
  isLoaded: false,
  selectedRecipe: null,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const loadRecipesAsync = createAsyncThunk(
  'recipes/loadRecipes',
  async (): Promise<Recipe[]> => {
    const response = await axios.get(recipesUrl);
    // The value we return becomes the `fulfilled` action payload
    return response.data.recipes as Recipe[];
  }
);

export const recipeSlice = createSlice({
  name: 'recipes',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setRecipes: (state, action: PayloadAction<Recipe[]>) => {
      state.recipes = action.payload;
      state.selectedRecipe = null;
      state.isLoaded = true;
    },
    randomizeRecipe: (state) => {
      const { recipes } = state;
      state.selectedRecipe = sample(recipes) ?? null;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(loadRecipesAsync.pending, (state) => {
        state.isLoaded = false;
      })
      .addCase(loadRecipesAsync.fulfilled, (state, action) => {
        state.isLoaded = true;
        state.recipes = action.payload;
        state.selectedRecipe = null;
      })
      .addCase(loadRecipesAsync.rejected, (state) => {
        state.isLoaded = false;
      });
  },
});

export const { setRecipes, randomizeRecipe } = recipeSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectRecipes = (state: RootState) => state.recipes;
export const selectRecipeLoading = (state: RootState) => state.recipes.isLoaded;
export const selectChosenRecipe = (state: RootState) => state.recipes.selectedRecipe;

export default recipeSlice.reducer;
